import React from 'react'
import { ApolloProvider } from '@apollo/client'
import type { AppProps } from 'next/app'
import { useApollo } from '../lib/apolloClient'
import ThemeWrapper from '@interflora/ui-components/build/components/ThemeWrapper/ThemeWrapper'
import { SearchProvider } from '@interflora/ui-components/build/components/SearchContext/SearchContext'
// import { withLDProvider } from 'launchdarkly-react-client-sdk'
import { withApplicationInsights } from 'next-applicationinsights'
import '../../public/css/fonts.css'

function App({ Component, pageProps }: AppProps) {
  const apolloClient = useApollo(pageProps)

  /*
   * Remove the server-side injected CSS when UI rendering kicks in.
   * This code comes from the MUI reference implementation of nextjs:
   * https://github.com/mui-org/material-ui/tree/master/examples/nextjs-with-typescript
   */
  React.useEffect(() => {
    const jssStyles = document.querySelector('#jss-server-side')
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles)
    }
  }, [])

  return (
    <SearchProvider>
      <ThemeWrapper>
        <ApolloProvider client={apolloClient}>
          <Component {...pageProps} />
        </ApolloProvider>
      </ThemeWrapper>
    </SearchProvider>
  )
}

const middlewareHostname = new URL(process.env.NEXT_PUBLIC_MIDDLEWARE_GRAPHQL_ENDPOINT).hostname

// Algolia Insights
const AppWithInsights = withApplicationInsights({
  instrumentationKey: process.env.NEXT_PUBLIC_APPINSIGHTS_INSTRUMENTATIONKEY,
  connectionString: process.env.NEXT_PUBLIC_APPLICATIONINSIGHTS_CONNECTION_STRING,
  isEnabled: !!process.env.NEXT_PUBLIC_APPINSIGHTS_INSTRUMENTATIONKEY,
  disableFetchTracking: false,
  enableCorsCorrelation: true,
  enableRequestHeaderTracking: true,
  enableResponseHeaderTracking: true,
  enableAutoRouteTracking: true,
  correlationHeaderDomains: [middlewareHostname],
})(App as any)

// Client side feature flags
// const AppWithFeatureFlags = withLDProvider({ clientSideID: process.env.NEXT_PUBLIC_LAUNCHDARKLY_SDK_CLIENT_SIDE_ID })(
//   AppWithInsights
// )

// export default AppWithFeatureFlags
export default AppWithInsights
